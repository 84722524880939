import { Theme, makeStyles } from "@material-ui/core";

import { REVEAL_TIME_MS } from "../../wordle/constants/settings";
import { WordToGifMap } from "../constants/settings";
import clsx from "clsx";

interface Props {
    letter: string;
    position: number;
    value?: string;
    shouldReveal?: boolean;
    status?: string;
    isFilled?: boolean;
};

export const LetterCell = ({
    letter,
    position,
    value,
    shouldReveal,
    status,
    isFilled
}: Props) => {
    const classes = useStyles();
    const animationDelay = `${REVEAL_TIME_MS}ms`

    const customClasses = clsx(
        'xxshort:w-11 xxshort:h-11 short:text-2xl short:w-12 short:h-12 w-14 h-14 border-solid border-2 flex items-center justify-center mx-0.5 text-4xl font-bold rounded text-white',
        {
            'bg-slate-900 border-slate-600':
                !status,
            'border-slate-100': value && !status,
            'absent shadowed bg-slate-700 text-white  border-slate-700':
                status === 'absent',
            'correct shadowed bg-green-500 text-white border-green-500':
                status === 'correct',
            'present shadowed bg-yellow-500 text-white border-yellow-500':
                status === 'present',
            'cell-fill-animation': isFilled,
            'cell-reveal': shouldReveal,
        }
    )

    return (
        <div className={classes.imageContainer}>
            <img className={classes.image} src={WordToGifMap[letter.toLowerCase()]} alt="emote" />
            <div className={customClasses} style={{ animationDelay }}>
                <div className="letter-container" style={{ animationDelay }}>
                    {value}
                </div>
            </div>
        </div>
    )
}



const useStyles = makeStyles((theme: Theme) => {
    return {
        imageContainer: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
        },
        image: {
            margin: '0 5px 5px',
            width: 25,
            height: 25
        },
    };
});