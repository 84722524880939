export const SYSTEM_COLORS = {
	BACKGROUND: '#F3F3F1',
	ERROR: '#EE0000',
	GRAY: '#7A766D',
	GRAY_10: '#DFDFD8',
	GRAY_SHADE: '#302E28',
	LIGHT_GRAY: '#A9A59C',
	LIGHT_GRAY_30: '#D2CFC7',
	ORANGE: '#EFAA59',
	PRIMARY: '#5FAF85',
	PRIMARY_SHADE: '#257A4E',
	PRIMARY_10: '#DBFEEC',
	SECONDARY: '#f5c400', //From guilded.gg
	SECONDARY_SHADE: '#C75341',
	TEAL: '#26647E',
	TERTIARY: '#71D8E5',
	TERTIARY_SHADE: '#12707D',
	TERTIARY_VARIANT: '#37B8CA',
	TERTIARY_10: '#D5F9FE',
	TERTIARY_HOVER: '#00E2FF',
	WARNING: '#EFAA59',
	WARNING_10: '#FFF0DF',
	WHITE: '#FFFFFF',
	TRANSPARENT_BORDER: 'rgba(48, 46, 40, 0.1)',
	GUILDED_GRAY: '#373943', //From guilded.gg ModalV2-container
	GUILDED_MENU_GRAY: '#4f515d'
};

export interface Hex {
	r: number;
	g: number;
	b: number;
}
export const hexToRgb = (hex: string): Hex => {
	const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
	if (!result) {
		return {
			r: 25,
			g: 144,
			b: 84
		};
	}
	return {
		r: parseInt(result[1], 16),
		g: parseInt(result[2], 16),
		b: parseInt(result[3], 16)
	};
};
export const getRGBAFromHex = (hex: string, alpha: number): string => {
	const { r, g, b } = hexToRgb(hex);
	return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};
