import { MAX_CHALLENGES } from '../../constants/settings';
import { CompletedRow } from './completedRow';
import { CurrentRow } from './currentRow';
import { EmptyRow } from './emptyRow';


interface Props {
    solution: string
    guesses: string[]
    currentGuess: string
    isRevealing?: boolean
    currentRowClassName: string
}

const Grid = ({
    solution,
    guesses,
    currentGuess,
    isRevealing,
    currentRowClassName,
}: Props) => {
    const empties =
        guesses.length < MAX_CHALLENGES - 1
            ? Array.from(Array(MAX_CHALLENGES - 1 - guesses.length))
            : []

    return (
        <>
            {guesses.map((guess, i) => (
                <CompletedRow
                    key={i}
                    solution={solution}
                    guess={guess}
                    isRevealing={isRevealing && guesses.length - 1 === i}
                />
            ))}
            {guesses.length < MAX_CHALLENGES && (
                <CurrentRow guess={currentGuess} className={currentRowClassName} />
            )}
            {empties.map((_, i) => (
                <EmptyRow key={i} />
            ))}
        </>
    )
}

export default Grid;