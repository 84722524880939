import './index.css';

import { BrowserRouter } from 'react-router-dom';
import { CssBaseline } from '@material-ui/core';
import { Fragment } from 'react';
import Home from './pages/home';
import { MuiThemeProvider } from '@material-ui/core/styles';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import theme from './core/theme';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
	// Why process.env.PUBLIC_URL?  Needed for hosting on Github pages
	// @see https://github.com/facebook/create-react-app/issues/1765#issuecomment-285114194
	<BrowserRouter basename={process.env.PUBLIC_URL}>
		<MuiThemeProvider theme={theme}>
			<Fragment>
				
			<CssBaseline />
			<Home />
			</Fragment>
		</MuiThemeProvider>
	</BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
