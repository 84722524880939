import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { Paper, Theme, makeStyles, withStyles } from '@material-ui/core';
import { ReactElement, useState } from 'react';
import { SYSTEM_COLORS, getRGBAFromHex } from '../../core/colors';

import Cryptoquip from '../../components/cryptoquip/cryptoquip';
import Div100vh from 'react-div-100vh'
import Finish from '../../components/finish/finish';
import Hunt from '../../components/hunt/hunt';
import Snackbar from '@material-ui/core/Snackbar';
import Step from '@material-ui/core/Step';
import StepConnector from '@material-ui/core/StepConnector';
import { StepIconProps } from '@material-ui/core/StepIcon';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import Welcome from '../../components/welcome/welcome';
import Wordle from '../../components/wordle/wordle';
import clsx from 'clsx';
import cryptoIcon from '../../assets/images/emotes/crypto.png';
import gWhiteIcon from '../../assets/images/emotes/g-white.png';
import gunIcon from '../../assets/images/emotes/gun.webp';
import treasureIcon from '../../assets/images/emotes/treasure.webp';
import wordleIcon from '../../assets/images/emotes/wordle.png';

function Alert(props: AlertProps) {
	return <MuiAlert elevation={6} variant="filled" {...props} />;
}

interface Props {
	c?: any;
}

const useStyles = makeStyles((theme: Theme) => {
	return {
		headerContainer: {
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'space-between',
			width: '100%'
		},
		paperContainer: {
			height: '100%',
			margin: "0 auto",
			maxWidth: '800px',
			borderRadius: 18,
			border: `1px solid ${SYSTEM_COLORS.GUILDED_MENU_GRAY}`,
			boxShadow: '0px 12px 37px 3px rgba(0, 0, 0, 0.5)',
			padding: 15,
			display: 'flex',
			alignItems: 'center',
			flexDirection: "column",
			backgroundColor: getRGBAFromHex(SYSTEM_COLORS.GUILDED_GRAY, .95),
		},
		headerText: {
			color: SYSTEM_COLORS.PRIMARY
		},
		linkEl: {
			color: SYSTEM_COLORS.SECONDARY
		},
		stepperContainer: {
			width: '100%',
			padding: '0 0 24px 0',
		},
		step: {
			[theme.breakpoints.down('sm')]: {
				paddingLeft: 0,
				paddingRight: 0
			}
		},
		stepContents: {
			width: '100%',
			flexGrow: 1,
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center',
		},
		wrapper: {
			padding: 15,
			height: '100%',
		}
	};
});

const ColorlibConnector = withStyles({
	alternativeLabel: {
		top: 22,
	},
	active: {
		'& $line': {
			backgroundImage:
				'linear-gradient(to right, #ffb400, #e4c519, #edd75c)',
		},
	},
	completed: {
		'& $line': {
			backgroundImage:
				'linear-gradient(to right, #ffb400, #e4c519, #edd75c)',
		},
	},
	line: {
		height: 3,
		border: 0,
		backgroundColor: '#eaeaf0',
		borderRadius: 1,
	},
})(StepConnector);

const useColorlibStepIconStyles = makeStyles({
	root: {
		backgroundColor: SYSTEM_COLORS.GUILDED_MENU_GRAY,
		zIndex: 1,
		color: '#fff',
		width: 50,
		height: 50,
		display: 'flex',
		borderRadius: '50%',
		justifyContent: 'center',
		alignItems: 'center',
	},
	active: {
		backgroundImage:
			'linear-gradient(to right, #ffb400, #e4c519, #edd75c)',
		boxShadow: '0 0 6px 0 rgb(255 234 0 / 50%)',
	},
	completed: {
		backgroundImage:
			'linear-gradient(to right, #ffb400, #e4c519, #edd75c)',
	},
});

function ColorlibStepIcon(props: StepIconProps) {
	const classes = useColorlibStepIconStyles();
	const { active, completed } = props;

	const icons: { [index: string]: React.ReactElement } = {
		1: <img src={gWhiteIcon} width="50" style={{ marginTop: 5 }} alt="G" />,
		2: <img src={wordleIcon} width="35" alt="Wordle" />,
		3: <img src={cryptoIcon} width="45" alt="Cryptogram" />,
		4: <img src={gunIcon} width="28" alt="Shooter game" />,
		5: <img src={treasureIcon} width="32" alt="Treasure!" />,
	};

	return (
		<div
			className={clsx(classes.root, {
				[classes.active]: active,
				[classes.completed]: completed,
			})}
		>
			{icons[String(props.icon)]}
		</div>
	);
}

const Home = (props: Props): ReactElement => {
	const classes = useStyles();
	const [activeStep, setActiveStep] = useState(0);
	const steps = ['Welcome', 'Game 1', 'Game 2', 'Game 3', 'The prize'];
	const [openSnackbar, setOpenSnackbar] = useState(false);

	// const showSuccessToast = (): void => {
	// 	setOpenSnackbar(true);
	// }

	const handleOnStepComplete = (completeStatus: boolean): void => {
		if (completeStatus) {
			if ([1, 2, 3].includes(activeStep)) {
				// This is causing a double render on components. 
				// note to move to a provide
				// showSuccessToast();
			}
			setActiveStep(activeStep + 1);
		}
	}

	const ConditionalDiv100vhHackWrapper = ({ condition, wrapper, children }: {
		condition: any;
		wrapper: any;
		children: any;
	}) =>
		condition ? wrapper(children) : children;

	return (
		<ConditionalDiv100vhHackWrapper
			condition={[1, 2].includes(activeStep)}
			wrapper={(children: any) => <Div100vh>{children}</Div100vh>}
		>
			<div className={classes.wrapper}>
				<Paper className={classes.paperContainer}>
					<div className={classes.headerContainer}>
						<Stepper className={classes.stepperContainer} alternativeLabel activeStep={activeStep} connector={<ColorlibConnector />}>
							{steps.map((label) => (
								<Step key={label} className={classes.step}>
									<StepLabel StepIconComponent={ColorlibStepIcon}></StepLabel>
								</Step>
							))}
						</Stepper>
					</div>
					<div className={classes.stepContents}>
						{0 === activeStep && (<Welcome onComplete={handleOnStepComplete} />)}
						{1 === activeStep && (<Wordle onComplete={handleOnStepComplete} />)}
						{2 === activeStep && (<Cryptoquip onComplete={handleOnStepComplete} openInstructions />)}
						{3 === activeStep && (<Hunt onComplete={handleOnStepComplete} />)}
						{4 === activeStep && (<Finish onComplete={handleOnStepComplete} />)}
					</div>
				</Paper>
			</div>



			<Snackbar open={openSnackbar} autoHideDuration={3000} onClose={() => setOpenSnackbar(false)}>
				<Alert severity="success">
					Nice - you passed!
				</Alert>
			</Snackbar>
		</ConditionalDiv100vhHackWrapper>
	);
};


export default Home;
