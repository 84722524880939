// import './styles.css';

import { MAX_CHALLENGES, REVEAL_TIME_MS, SOLUTION } from './constants/settings';
import { isWinningWord, isWordInWordList, unicodeLength } from './lib/words';

import GraphemeSplitter from 'grapheme-splitter';
import Grid from './components/grid/grid';
import Keyboard from './components/keyboard/keyboard';
import { useState } from 'react';

interface Props {
    onComplete(didPass: boolean): void;
}

const Wordle = (props: Props) => {
    const [guesses, setGuesses] = useState<string[]>([]);
    const [currentGuess, setCurrentGuess] = useState('');
    const [isRevealing, setIsRevealing] = useState(false);
    const [currentRowClass, setCurrentRowClass] = useState('');
    const [isGameWon, setIsGameWon] = useState(false);
    const [isGameLost, setIsGameLost] = useState(false);

    const onChar = (value: string) => {
        if (
            unicodeLength(`${currentGuess}${value}`) <= SOLUTION.length &&
            guesses.length < MAX_CHALLENGES &&
            !isGameWon
        ) {
            setCurrentGuess(`${currentGuess}${value}`)
        }
    }

    const onEnter = () => {
        if (isGameWon || isGameLost) {
            return
        }

        if (!(unicodeLength(currentGuess) === SOLUTION.length)) {
            setCurrentRowClass('jiggle')
            //   return showErrorAlert(NOT_ENOUGH_LETTERS_MESSAGE, {
            // 	onClose: clearCurrentRowClass,
            //   })
        }

        if (!isWordInWordList(currentGuess)) {
            setCurrentRowClass('jiggle')
            //   return showErrorAlert(WORD_NOT_FOUND_MESSAGE, {
            // 	onClose: clearCurrentRowClass,
            //   })
        }



        setIsRevealing(true)
        // turn this back off after all
        // chars have been revealed
        setTimeout(() => {
            setIsRevealing(false)
        }, REVEAL_TIME_MS * SOLUTION.length)

        const winningWord = isWinningWord(currentGuess)

        if (
            unicodeLength(currentGuess) === SOLUTION.length &&
            guesses.length < MAX_CHALLENGES &&
            !isGameWon
        ) {
            setGuesses([...guesses, currentGuess])
            setCurrentGuess('')

            if (winningWord) {
                props.onComplete(true);
                return setIsGameWon(true)
            }

            if (guesses.length === MAX_CHALLENGES - 1) {
                props.onComplete(false);
                return setIsGameLost(true)
                // showErrorAlert(CORRECT_WORD_MESSAGE(solution), {
                // 	persist: true,
                // 	delayMs: REVEAL_TIME_MS * solution.length + 1,
                // })
            }
        }
    }

    const onDelete = () => {
        setCurrentGuess(
            new GraphemeSplitter().splitGraphemes(currentGuess).slice(0, -1).join('')
        )
    }

    return (
        <div className="mx-auto flex w-full grow flex-col px-1 pt-2 pb-8 sm:px-6 md:max-w-7xl lg:px-8 short:pb-2 short:pt-2">
            <div className="flex grow flex-col justify-center pb-6 short:pb-2">
                <Grid
                    solution={SOLUTION}
                    guesses={guesses}
                    currentGuess={currentGuess}
                    isRevealing={isRevealing}
                    currentRowClassName={currentRowClass}
                />
            </div>
            <Keyboard
                onChar={onChar}
                onDelete={onDelete}
                onEnter={onEnter}
                solution={SOLUTION}
                guesses={guesses}
                isRevealing={isRevealing}
            />
        </div>

    );
};
export default Wordle;
