import Cash from '../../../assets/images/emotes/gil-cashtrain.webp';
import Cheer from '../../../assets/images/emotes/gil-cheer.webp';
import Clap from '../../../assets/images/emotes/gil-clap.gif';
import Heart from '../../../assets/images/emotes/gil-heart.webp';
import Rainbow from '../../../assets/images/emotes/gil-pukerainbow.gif';
import Stab from '../../../assets/images/emotes/gil-stab.webp';

export const CRYPTO_SOLUTION = 'gotta go fast';

export const WordToGifMap: { [key: string]: string } = {
    'g': Rainbow,
    'o': Cash,
    't': Heart,
    'a': Clap,
    'f': Stab,
    's': Cheer
}

