import { CardActionArea, Theme, makeStyles } from "@material-ui/core";

import { LetterCell } from "./letterCell";
import { ReactElement } from "react";

interface Props {
    rowChunk: string;
    guesses: { [key: string]: string };
    setLetterGuessing: (letter: string) => void;
    letterGuessing?: string;
};

export const Row = ({ rowChunk, letterGuessing, setLetterGuessing, guesses }: Props) => {
    const classes = useStyles();
    const letters = rowChunk.split('');
    return <div className={classes.row}>
        {letters.map((letter, position): ReactElement => {
            let status = '';
            if(guesses[letter] === letter) {
                status = 'correct'
            } else if (letter === letterGuessing) {
                status = 'present';
            }

            return (
                <CardActionArea className={classes.clickableEl} key={position} onClick={(): void => {
                    setLetterGuessing(letter);
                }}>
                    <LetterCell
                        value={guesses[letter]}
                        letter={letter}
                        position={position}
                        status={status}
                        shouldReveal={letter === letterGuessing}
                    />
                </CardActionArea>
            )
        })}
    </div>

}


const useStyles = makeStyles((theme: Theme) => {
    return {
        clickableEl: {
            width: 'auto'
        },
        row: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        },
    };
});