import { Link, Theme, Typography, makeStyles } from "@material-ui/core";

function importAll(r: any) {
    return r.keys().map(r);
}

const images = importAll(require.context('../../assets/images/gifs/', false, /\.(gif)$/));

interface Props {
    onComplete(didPass: boolean): void;
}

const useStyles = makeStyles((theme: Theme) => {
    return {
        root: {
            display: 'flex',
            flexDirection: 'column',
        },
        img: {
            height: 50
        },
        treasureContainer: {

        },
        gifSpam: {
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'center',
            gap: 10,
            margin: '10px 0 50px 0'
        },
    };
});

const Finish = (props: Props) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <div className={classes.gifSpam}>
                {images.map((imgSrc: any) => (
                    <img className={classes.img} src={imgSrc} alt="90s gif" />
                ))}
            </div>

            <div className={classes.treasureContainer}>
                <Typography align="center" variant='body1'>You WIN! Claim your prize at <br /><Link target="_blank" href="https://www.guilded.gg/i/EoeXnGqk?cid=c526ee96-18c5-4490-b197-1b6802894dc1&intent=chat">guilded.gg/i/EoeXnGqk</Link></Typography>
            </div>
        </div>
    );
};
export default Finish;