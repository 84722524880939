import GraphemeSplitter from "grapheme-splitter"
import { SOLUTION } from "../constants/settings"
import { VALID_GUESSES } from "../constants/validGuesses"
import { WORDS } from "../constants/wordlist"

export const unicodeSplit = (word: string) => {
    return new GraphemeSplitter().splitGraphemes(word)
}

export const localeAwareLowerCase = (text: string) => {
    return text.toLowerCase()
}

export const localeAwareUpperCase = (text: string) => {
    return text.toUpperCase()
}

export const unicodeLength = (word: string) => {
    return unicodeSplit(word).length
}

export const isWordInWordList = (word: string) => {
    return (
        WORDS.includes(localeAwareLowerCase(word)) ||
        VALID_GUESSES.includes(localeAwareLowerCase(word))
    )
}

export const isWinningWord = (word: string) => {
    return SOLUTION === word
}