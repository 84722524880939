import { Button, DialogActions, makeStyles, Theme } from '@material-ui/core';
import { ReactElement, ReactNode } from 'react';


interface Props {
    saveLabel?: string;
    saveAddtLabel?: string;
    cancelAction?(): void;
    saveAction?(): void;
    saveAddtAction?(): void;
    cancelLabel?: string;
    disableSave?: boolean;
    children?: ReactNode;
}

const DialogActionBar = (props: Props): ReactElement => {
    const classes = useStyles();

    return (
        <DialogActions
            classes={{
                root: classes.containerRoot
            }}
        >
            {props.children}
            {props.cancelAction && (
                <Button variant="contained" color='secondary' onClick={props.cancelAction} className={classes.cancel}>
                    {props.cancelLabel ? props.cancelLabel : 'Cancel'}
                </Button>
            )}
            {props.saveAddtAction && (
                <Button
                    variant="contained"
                    color='primary'
                    onClick={props.saveAddtAction}
                    className={classes.button}
                    disabled={props.disableSave}
                >
                    {props.saveAddtLabel ? props.saveAddtLabel : 'Save Extra'}
                </Button>
            )}
            {props.saveAction && (
                <Button
                    variant="contained"
                    color='primary'
                    onClick={props.saveAction}
                    className={classes.button}
                    disabled={props.disableSave}
                >
                    {props.saveLabel ? props.saveLabel : 'Save'}
                </Button>
            )}
        </DialogActions>
    );
};

const useStyles = makeStyles((theme: Theme) => {
    return {
        containerRoot: {
            borderTop: `1px solid ${theme.palette.divider}`,
            padding: '21px 24px'
        },
        button: {
            [theme.breakpoints.down('sm')]: {
                width: '100%'
            }
        },
        cancel: {
            [theme.breakpoints.down('sm')]: {
                display: 'none'
            }
        }
    };
});

export default DialogActionBar;