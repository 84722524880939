import { CRYPTO_SOLUTION, WordToGifMap } from './constants/settings';
import { CardActionArea, Theme, Typography } from '@material-ui/core';
import { useEffect, useState } from 'react';

import DialogInstructions from './components/dialogInstructions';
import Keyboard from '../wordle/components/keyboard/keyboard';
import { Row } from './components/row';
import { makeStyles } from '@material-ui/styles';

interface Props {
    openInstructions: boolean; 
    onComplete(didPass: boolean): void;
}

const Cryptoquip = (props: Props) => {
    const classes = useStyles();
    const [guesses, setLetterGuess] = useState<{ [key: string]: string }>({'s': 's'});
    const [letterGuessing, setLetterGuessing] = useState<string | undefined>();
    const [open, setOpen] = useState(props.openInstructions);

    const solutionChunks = CRYPTO_SOLUTION.split(' ');

    const onChar = (value: string) => {
        if (letterGuessing) {
            setLetterGuess({
                ...guesses,
                [letterGuessing]: value.toLowerCase()
            })
            setLetterGuessing(undefined);
        }
    }


    const onEnter = () => {
        const successKeys = Object.keys(guesses).map((key) => {
            return key.toLowerCase() === guesses[key].toLowerCase();
        }).filter((a) => a);

        if (successKeys.length === Object.keys(WordToGifMap).length) {
            props.onComplete(true);
        } else {
            props.onComplete(false);
        }
    }


    return (
        <div className="mx-auto flex w-full grow flex-col px-1 pt-2 pb-8 sm:px-6 md:max-w-7xl lg:px-8 short:pb-2 short:pt-2">
            <div className="flex grow flex-col justify-center pb-6 short:pb-2">
                <Typography variant='body1' align='center'>Cryptogram time</Typography>
                <CardActionArea onClick={(): void => {
                    setOpen(true);
                }}>
                    <Typography variant='body2' align='center'>Instructions</Typography>
                </CardActionArea>
                <div className={classes.quipKeyContainer}>
                    {solutionChunks.map((rowChunk, i) => {
                        return (
                            <Row
                                setLetterGuessing={setLetterGuessing}
                                letterGuessing={letterGuessing}
                                guesses={guesses}
                                key={i}
                                rowChunk={rowChunk}
                            />
                        )
                    })}
                </div>
            </div>
            <Keyboard
                onChar={onChar}
                onEnter={onEnter}
                solution={CRYPTO_SOLUTION}
                guesses={['']}
                isRevealing={false}
            />
            <DialogInstructions open={open} closeDialog={(): void => setOpen(false)}/>
        </div>
    )
};


const useStyles = makeStyles((theme: Theme) => {
    return {
        container: {},
        quipKeyContainer: {
            padding: '25px 0',
            display: 'grid',
            gridTemplateColumns: 'repeat(1, 1fr)',
            gap: 15
        },

    };
});
export default Cryptoquip;