import { Button, Theme, Typography, makeStyles } from "@material-ui/core";

import GGif from '../../assets/images/gifs/Guilded_Logo.gif';
import GHeart from '../../assets/images/emotes/gil-heart.webp';

interface Props {
    onComplete(didPass: boolean): void;
}

const useStyles = makeStyles((theme: Theme) => {
    return {
        root: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
        },
        button: {
            width: 266,
            marginTop: 32,
            marginBottom: 32
        },
        instructionText: {
            maxWidth: 300,
            marginTop: 20,
        },
        heartIcon: {
            width: 20,
            display: 'inline-block',
            verticalAlign: 'text-top',
            marginRight: 5,
        }
    }
});

const Welcome = (props: Props) => {
    const classes = useStyles();

    return (<div className={classes.root}>
        <img src={GGif} alt="Spinning Guilded Tile" />
        <Typography variant='body1' align="center" className={classes.instructionText}>Complete the three challenges to win Guilded treasure!</Typography>
        <Typography variant='body1' align="center" className={classes.instructionText}>SO to Jared & Matt for graphics! <img src={GHeart} alt="Gil-heart" className={classes.heartIcon} /></Typography>
        <Button onClick={() => { props.onComplete(true) }} variant="contained" color="primary" size="large" className={classes.button}>
            Lets go!
        </Button>
    </div>)
};
export default Welcome;