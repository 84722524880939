import { SYSTEM_COLORS } from './colors';
import { createTheme } from '@material-ui/core';

const BASE_FONT_SIZE = 16;

export const getRemFromPx = (fontSizePx: number): string => `${(fontSizePx / BASE_FONT_SIZE).toFixed(4)}rem`;

const theme = createTheme({
	palette: {
		primary: {
			main: SYSTEM_COLORS.SECONDARY,
			contrastText: '#FFF'
		},
		secondary: {
			main: '#FFF'
		}
	},
	typography: {
		allVariants: { color: 'white' },
		fontFamily: ['Poppins', 'sans-serif'].join(','),

		h1: {
			fontSize: getRemFromPx(44),
			fontFamily: ['Aleo', 'serif'].join(','),
			lineHeight: getRemFromPx(53),
			fontWeight: 'bold'
		},
		h2: {
			fontSize: getRemFromPx(24),
			lineHeight: getRemFromPx(30),
			fontWeight: 600,

		},
		h3: {
			fontSize: getRemFromPx(18),
			fontFamily: ['Aleo', 'serif'].join(','),
			lineHeight: getRemFromPx(22),
			fontWeight: 'bold',
		},
		h4: {
			fontSize: getRemFromPx(16),
			lineHeight: getRemFromPx(20),
			fontWeight: 600,

		},
		h5: {
			fontSize: getRemFromPx(14),
			lineHeight: getRemFromPx(21),
			fontWeight: 600,

		},
		h6: {
			fontSize: getRemFromPx(12),
			lineHeight: getRemFromPx(18),
			fontWeight: 600,

		},
		body1: {
			// P1
			fontSize: getRemFromPx(18),
			lineHeight: getRemFromPx(27)
		},
		body2: {
			// P2
			fontSize: getRemFromPx(16),
			lineHeight: getRemFromPx(24)
		},
		subtitle1: {
			// Smalltext
			fontSize: getRemFromPx(10),
			lineHeight: getRemFromPx(15),
			fontWeight: 600
		},
		button: {
			color: 'backgroundLight',
			textTransform: 'none',
			fontSize: getRemFromPx(16),
			lineHeight: getRemFromPx(24),
			fontWeight: 600
		}
	},
	breakpoints: {
		values: {
			xl: 1280,
			lg: 950,
			md: 750,
			sm: 475,
			xs: 0
		}
	},
	overrides: {
		MuiListItem: {
			button: {
				margin: '4px 0',
				borderRadius: 8,
				'&:hover': {
					backgroundColor: SYSTEM_COLORS.BACKGROUND
				}
			}
		},
		MuiListItemText: {
			primary: {
				color: SYSTEM_COLORS.GRAY,
				textShadow: 'none',
				fontSize: getRemFromPx(12),
				lineHeight: getRemFromPx(18),
				fontWeight: 600
			},
			secondary: {
				fontSize: getRemFromPx(14),
				lineHeight: getRemFromPx(21),
				fontWeight: 600
			}
		},
		MuiListItemIcon: {
			root: {
				color: SYSTEM_COLORS.GRAY
			}
		},
		MuiButton: {
			text: {},
			root: {
				borderRadius: 8
			},
			contained: {
				boxShadow: 'none'
			},
			sizeSmall: {
				fontSize: getRemFromPx(12),
				lineHeight: getRemFromPx(18),
				fontWeight: 600,
				padding: '11px 20px'
			},
			sizeLarge: {
				fontSize: getRemFromPx(18),
				lineHeight: getRemFromPx(18),
				fontWeight: 600,
				padding: '16px 24px',
				minWidth: 100
			},
			containedPrimary: {
				backgroundImage: 'linear-gradient(to right, #ffb400, #e4c519, #edd75c)',
				border: 'solid 1px gold',
				boxShadow: '0 0 6px 0 rgb(255 234 0 / 50%)',
				color: '#1d1f24'
			},
			containedSecondary: {
				border: `1px solid ${SYSTEM_COLORS.LIGHT_GRAY_30}`,
				'&:hover': {
					backgroundColor: SYSTEM_COLORS.BACKGROUND,
					boxShadow: 'none'
				}
			}
		},
		MuiTabs: {
			indicator: {
				height: 4
			}
		},
		MuiTab: {
			root: {
				textShadow: 'none',
				fontSize: `${getRemFromPx(12)} !important`,
				lineHeight: `${getRemFromPx(18)} !important`,
				fontWeight: 600
			}
		},
		MuiInput: {
			input: {
				paddingTop: 16,
				'&::placeholder': {
					paddingTop: 16
				}
			}
		},
		MuiPaper: {
			root: {
				backgroundColor: 'transparent',
				color: '#FFFFFF'
			},
			elevation1: {
				boxShadow: '0px 3px 3px rgba(0, 0, 0, 0.03)'
			},
			rounded: {
				borderRadius: 16
			}
		},
		MuiPopover: {
			paper: {
				boxShadow: '0px 6px 20px rgba(0, 0, 0, 0.12)'
			}
		},
		MuiTableCell: {
			head: {
				textShadow: 'none',
				fontSize: getRemFromPx(13),
				lineHeight: getRemFromPx(19),
				color: SYSTEM_COLORS.GRAY
			}
		},
		MuiStepLabel: {
			label: {
				color: 'white',
				fontWeight: 'bold'
			},
			active: {
				color: 'white',
				fontWeight: 'bold'
			}
		},
		MuiOutlinedInput: {
			notchedOutline: {
				borderColor: SYSTEM_COLORS.SECONDARY,
			}
		},
	}
});

export default theme;