import { InputAdornment, Link, TextField, Theme, Typography, makeStyles } from "@material-ui/core";

import landscapeImg from '../../assets/images/empty-state/landscape-white.png';
import runToBossImg from '../../assets/images/empty-state/gil-run-to-boss.png';

const THE_CODE = 'GIL';

interface Props {
    onComplete(didPass: boolean): void;
}

const useStyles = makeStyles((theme: Theme) => {
    return {
        root: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            '& .MuiInputBase-root': {
                color: 'white'
            },
        },
        img: {
            height: 300
        },
        icon: {
            height: 18,
            display: "inline"
        },
        list: {
            listStyle: "decimal-leading-zero"
        },
        codeInput: {
            marginTop: 30,
        },
        treasureContainer: {
            marginTop: 30,
        }
    };
});

const Hunt = (props: Props) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <img src={runToBossImg} alt="Boss fight!" className={classes.img} />
            <ol className={classes.list}>
                <li><Typography variant='body1'>Flip your phone landscape <img src={landscapeImg} className={classes.icon} alt="landscape" /></Typography></li>
                <li><Typography variant='body1'>Click <Link target="_blank" href="https://s3.us-east-1.amazonaws.com/apps.pwebo.com/apps/DuckHunt-JS/index.html">this link</Link></Typography></li>
                <li><Typography variant='body1'>Win game and get code</Typography></li>
                <li><Typography variant='body1'>Enter code below</Typography></li>
            </ol>

            <TextField id="outlined-basic" label="The code" variant="outlined" InputProps={{
                startAdornment: <InputAdornment position="start"></InputAdornment>,
            }}
                className={classes.codeInput} onChange={(e) => {
                    if (THE_CODE === e.target.value.toUpperCase()) {
                        props.onComplete(true);
                    }
                }} />
        </div>
    );
};
export default Hunt;