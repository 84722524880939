// import { solution } from '../../lib/words'
import { SOLUTION } from '../../constants/settings';
import { Cell } from './cell';

export const EmptyRow = () => {
    const emptyCells = Array.from(Array(SOLUTION.length))

    return (
        <div className="mb-1 flex justify-center">
            {emptyCells.map((_, i) => (
                <Cell key={i} />
            ))}
        </div>
    )
}


export default EmptyRow;