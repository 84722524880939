import { DialogContent, Link, Theme, Typography } from '@material-ui/core';

import DialogActionBar from '../../../core/component/dialogActionBar';
import DialogBase from '../../../core/component/dialogBase';
import DialogTitleHeader from '../../../core/component/dialogTitleBar';
import { makeStyles } from '@material-ui/styles';

interface Props {
    closeDialog(): void;
    open: boolean;
}

const DialogInstructions = (props: Props) => {
    const classes = useStyles();


    return (
      <DialogBase open={props.open} containerClass={classes.container}>
        <DialogTitleHeader title='Instructions' closeDialog={props.closeDialog} />
        <DialogContent>
            <Typography variant='body1' className={classes.typography}>Next up is a <Link href="https://en.wikipedia.org/wiki/Cryptogram" target='_blank'>Cryptogram</Link>! Each of these wonderfully designed icons represents a letter. To enter your guess, click in a box, then type a letter. A green box will indicate you've guessed correctly!</Typography>
        </DialogContent>
        <DialogActionBar 
            saveLabel='Lets Go!'
            saveAction={props.closeDialog}
        />
      </DialogBase>
    )
};


const useStyles = makeStyles((theme: Theme) => {
    return {
        container: {
            backgroundColor: 'white'
        },
        quipKeyContainer: {
            padding: '25px 0',
            display: 'grid',
            gridTemplateColumns: 'repeat(1, 1fr)',
            gap: 15
        },
        typography: {
            color: 'black'
        }

    };
});
export default DialogInstructions;